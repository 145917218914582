<template>
    <div class="buffet-intro">

		<div :class="{'pending-orders-overlay': true, 'visible': pendingOrdersVisible}" v-if="hasPendingOrders">
			<router-link :to="'/track-orders'" class="yellowBtn">
				{{ $t('track-orders-text')}}
			</router-link>
		</div>

        <div class="slides" v-if="bullets">
            <transition-group name="fade" mode="out-in">
                <div class="slide" v-for="(slide, key) in bullets" :key="'slide-'+key" :class="{'visible': currentStep == (key + 1)}">
                    <div class="image">
                        <img :src="slide.image" alt="" class="img-mobile">
                        <img :src="slide.imageBig" alt="" class="img-desktop">
                    </div>

                    <div class="bottom">
                        <div class="bullets">
                            <button class="bullet" 
                            :key="'bullet-'+bkey"
                            v-for="(bullet, bkey) in bullets" 
                            :class="{'active': currentStep == (bkey + 1)}"
                            @click="setSlide(bkey+1)"
                            ></button>
                        </div>
                        <div class="text">
                            <p v-t="slide.text"></p>
                        </div>

                        <div class="buttons">
                            <button class="yellowBtn" @click="next()">Következő</button>
                            <button class="greyBtn" @click="skip()">Kihagyás</button>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>

    </div>
</template>

<script>
const image1 = require("@/assets/images/intro/intro-1.png");
const image1Big = require("@/assets/images/intro/intro-1@2x.png");
const image2 = require("@/assets/images/intro/intro-2.png");
const image2Big = require("@/assets/images/intro/intro-2@2x.png");
const image3 = require("@/assets/images/intro/intro-3.png");
const image3Big = require("@/assets/images/intro/intro-3@2x.png");


export default {
    data() {
        return {
            currentStep: 1,
            bullets: [
                {
                    image: image1,
                    imageBig: image1Big,
                    text: 'tutorial-text-1'
                },
                {
                    image: image2,
                    imageBig: image2Big,
                    text: 'tutorial-text-2'
                },
                {
                    image: image3,
                    imageBig: image3Big,
                    text: 'tutorial-text-3'
                }
            ],
            pendingOrdersVisible: true
        }
    },
    computed: {
        introSkipped() {
            return this.$store.state.introSkipped;
        },
        hasPendingOrders() {
            return this.$store.getters['hasPendingOrders'];
        }
    },
    created() {
        if(this.introSkipped === true) {
            this.$router.push('/sector-selector')
        }

        setTimeout(() => {
            this.pendingOrdersVisible = false
        }, 5000);
    },
    methods: {
        next() {
            if(this.currentStep == 3) {
                this.$store.commit('setIntroSkipped', true);
                this.$router.push('/sector-selector');
            }

            if(this.currentStep <= 3) {
                this.currentStep++
            }
        },
        skip() {
            this.$store.commit('setIntroSkipped', true);
            this.$router.push('/sector-selector');
        },
        setSlide(value) {
            this.currentStep = value;
        }
    }
}
</script>